import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Spinner } from '@amzn/awsui-components-react';
import { useSelector } from 'react-redux';

import { PurchaseDetailPagePanels } from 'modules/purchase/constants';
import { PurchaseDetailTable } from 'modules/purchase/components/PurchaseDetailTable';
import { InvoiceLineTable } from 'modules/invoices/components/InvoiceLineTable';
import {
    ApplicationState,
    getLine,
    isRequisition,
    getCanDisableEdit,
    getUserEditStatus,
    isQuantityBasedLine
} from 'modules/app/store';
import { I18nFormatter } from 'modules/core/class';
import { PurchaseLineHeader } from '../PurchaseLineHeader';

import styles from '../PurchaseLineWrapper/Wrapper.module.scss';
import { ReceiptStatus } from '@amzn/merp-core/models';

interface ViewPurchaseLineProps {
    lineNumber: number;
    loadingReceipts?: boolean;
    loadingInvoiceLines?: boolean;
    onEdit?: () => void;
    onHelp?: (key: PurchaseDetailPagePanels, ...args: unknown[]) => void;
}

export const ViewPurchaseLine = (props: ViewPurchaseLineProps) => {
    const { lineNumber, loadingInvoiceLines, loadingReceipts, onHelp, onEdit } = props;

    const { t } = useTranslation('purchase');

    const isPurchaseRequest = useSelector(isRequisition);
    const line = useSelector((state: ApplicationState) => getLine(state.purchaseDetails, lineNumber));
    const canDisableEdit = useSelector((state: ApplicationState) => getCanDisableEdit(state.purchaseDetails));
    const validUserEditStatus = useSelector((state: ApplicationState) => getUserEditStatus(state));

    const isQuantityBased = isQuantityBasedLine(line);

    const { lastModifiedTime, uncommittedBalance } = line;
    const receipts = line.receipts.filter(r => r.receiptStatus !== ReceiptStatus.VOIDED);

    const auditHistoryPath = `/audit/${line.purchaseId}`;

    const title = isPurchaseRequest ? 'PR' : 'PO';
    const INVALID_SPEND_WARNING = t('line.modal.invalidSpendDistribution', { purchaseType: `${title}` });

    const onSpendInfoClicked = () => {
        if (onHelp) onHelp('SPEND_HELP');
    };

    const onSpendConfirmationInfoClicked = () => {
        if (onHelp) onHelp('SPEND_CONFIRMATION_HELP');
    };

    const onReceiptInfoClicked = () => {
        if (onHelp) onHelp('RECEIPT_HELP');
    };

    const onReceiptStatusInfoClicked = () => {
        if (onHelp) onHelp('RECEIPT_STATUS_HELP');
    };

    const LastUpdated = () => {
        receipts.sort((r1, r2) => r2.lastModifiedTime! - r1.lastModifiedTime!);
        return (
            <div className="mt-2">
                <div className={styles['font-size-medium']}>
                    {t('line.lastUpdated')} {I18nFormatter.date(t, lastModifiedTime)}: &nbsp;
                    {receipts?.length > 0 &&
                        receipts[0]?.versionCategory &&
                        t(`line.updateReasons.${receipts[0]?.versionCategory}`)}
                    <a
                        className={styles['font-size-small']}
                        href={auditHistoryPath}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        &nbsp;{t('overview.viewEdit')}&nbsp;
                    </a>
                </div>
                <div>{receipts?.length > 0 && receipts[0]?.versionDescription}</div>
            </div>
        );
    };

    const ViewPurchaseDetails = () => {
        return (
            <>
                <div className="awsui-util-label">
                    {t('line.receiptInformation')} ({receipts?.length})
                </div>
                <PurchaseDetailTable
                    receipts={receipts}
                    line={line}
                    uncommittedBalance={uncommittedBalance}
                    onSpendInfoClicked={onSpendInfoClicked}
                    onSpendConfirmationInfoClicked={onSpendConfirmationInfoClicked}
                    onReceiptInfoClicked={onReceiptInfoClicked}
                    onReceiptStatusInfoClicked={onReceiptStatusInfoClicked}
                />
                {!isPurchaseRequest && <LastUpdated />}
            </>
        );
    };

    const LoadingReceipts = () => (
        <div className={styles['spinner-centered']}>
            <Spinner />
        </div>
    );

    const disableEdit = !!loadingReceipts;
    const LineHeader = () => {
        return (
            <div className="awsui-util-action-stripe awsui-util-spacing-v-s">
                <PurchaseLineHeader line={line} />
                <div>
                    {validUserEditStatus && (
                        <Button
                            data-cy="editLine"
                            formAction="none"
                            variant="primary"
                            onClick={onEdit}
                            disabled={disableEdit || canDisableEdit}
                        >
                            {t('wrapper.edit')}
                        </Button>
                    )}
                </div>
            </div>
        );
    };

    return (
        <>
            {!line.hasValidDistribution && (
                <Alert data-cy="invalidSpendDistribution" header={INVALID_SPEND_WARNING} type="warning"></Alert>
            )}
            <div className="awsui-util-spacing-v-s">
                <LineHeader />
                {loadingReceipts ? <LoadingReceipts /> : <ViewPurchaseDetails />}

                {!isPurchaseRequest && (
                    <div className={styles['invoice-lines-container']}>
                        <InvoiceLineTable
                            loading={loadingInvoiceLines}
                            lineNumber={lineNumber}
                            isQuantityBased={isQuantityBased}
                        />
                    </div>
                )}
            </div>
        </>
    );
};
