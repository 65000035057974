import React from 'react';

import { TableRow } from 'modules/core/model';
import { ExpandableCell } from 'modules/core/components/Table';
import { PurchaseIndex } from '@amzn/merp-core/models/purchase';

import styles from './ConfirmStatusCell.module.scss';
import { Receipt } from 'modules/purchase/models';
import { Receipt as TSCoreReceipt } from '@amzn/merp-core/models';
import { DateGenerator } from 'modules/core/class';
import { useTranslation } from 'react-i18next';

interface PurchaseStatusCellProps {
    row: TableRow<PurchaseIndex>;
}

const closedStatuses = ['Soft Closed', 'Closed', 'Cancelled', 'Pending Soft Close'];

export const ConfirmStatusCell = ({ row }: PurchaseStatusCellProps) => {
    const { statusKeyword, receipts } = row.payload;
    const { t } = useTranslation('purchase');

    let receiptConfirmationStatusOfPurchaseOrder = '';

    const atLeastOneReceiptIsUnconfirmed = (receipts: Map<number, TSCoreReceipt[]> | undefined): boolean => {
        const hasUnconfirmedReceipt = (r: TSCoreReceipt) => {
            const receiptCopy: Receipt = Object.assign(new Receipt(), r);
            return receiptCopy.qualifiesForConfirmation;
        };

        const entries = Array.from(receipts?.values() ?? []);
        return entries.find(r => r.some(hasUnconfirmedReceipt)) !== undefined;
    };

    const atLeastOneReceiptIsConfirmed = (receipts: Map<number, TSCoreReceipt[]> | undefined): boolean => {
        if (!receipts?.size) {
            return false;
        }

        const endOfLastDayOfTheMonth = DateGenerator.getEndOfTheLastDayOfTheCurrentMonth();

        const hasConfirmedReceipt = (r: TSCoreReceipt) => {
            const receiptCopy: Receipt = Object.assign(new Receipt(), r);
            return !receiptCopy.qualifiesForConfirmation;
        };

        const entries = Array.from(receipts?.values() ?? []).filter(
            r => Object.assign(new Receipt(), r).receiptTime <= endOfLastDayOfTheMonth
        );

        return entries.length > 0 && entries.find(r => r.some(hasConfirmedReceipt)) !== undefined;
    };

    const getPurchaseOrderReceiptConfirmationStatus = (receipts: Map<number, TSCoreReceipt[]> | undefined): string => {
        if (atLeastOneReceiptIsUnconfirmed(receipts)) {
            return t('confirmation.unconfirmed');
        }

        if (atLeastOneReceiptIsConfirmed(receipts)) {
            return t('confirmation.isConfirmed');
        }
        return t('line.noSpendActivity');
    };

    const isPOClosed = closedStatuses.includes(statusKeyword!);

    receiptConfirmationStatusOfPurchaseOrder = isPOClosed
        ? statusKeyword!
        : getPurchaseOrderReceiptConfirmationStatus(receipts);

    const className = receiptConfirmationStatusOfPurchaseOrder.toLowerCase().replace(/\s/g, '') || '';

    return (
        <ExpandableCell
            row={row}
            cell={<div className={styles[className]}>{receiptConfirmationStatusOfPurchaseOrder || '-'}</div>}
            content={row.payload.purchaseLines?.map(l => (
                <div key={l.lineNumber}></div>
            ))}
        />
    );
};
