export enum ReceiptStatus {
    PENDING_APPROVAL = 'PENDING_APPROVAL',
    PENDING_POST = 'PENDING_POST',
    POSTED = 'POSTED',
    POST_FAILURE = 'POST_FAILURE',
    PENDING_VOID = 'PENDING_VOID',
    VOID_FAILURE = 'VOID_FAILURE',
    VOIDED = 'VOIDED',
    ABANDONED = 'ABANDONED'
}
